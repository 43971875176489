document.addEventListener('DOMContentLoaded', () => {
    // Add class to all required labels
    const fields = document.querySelectorAll('fieldset');
    if(fields.length) {
        fields.forEach((field) => {
            const requiredField = field.querySelector('.required-field');
            const label = field.querySelector('label');
            
            if(requiredField !== null) {
                label.classList.add('required-label');
            }
            
        });
    }
    
    // Set target _blank on all company links in maps
    const companyLocationMap = document.querySelectorAll('.company-location-map');
    if(companyLocationMap.length) {
        companyLocationMap.forEach((company) => {
            const link = company.querySelector('a');

            link.setAttribute('target', '_blank');
        });
    }

    const companyVideos = document.querySelectorAll('.company_video');
    if (companyVideos.length) {
        companyVideos.forEach((video) => {
            const iframe = video.querySelector('iframe');
            iframe.removeAttribute('height');
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('style', 'aspect-ratio: 16 / 9;');
        })
    }
});
